<template>
  <div class="wrapper">
    <div class="container">
      <div class="section__line">
        <div class="link__top">
          <a class="link__back" @click="$router.go(-1)">
            <span class="link__back--img">
              <img src="../../../assets/img/arrow-back.svg" />
            </span>
            Назад
          </a>
        </div>
      </div>
      <div class="section__bg section--bank__info section--document_preview">
        <div class="document_preview--top">
          <div class="document_preview--left">
            <div class="document_preview--info">
              <div class="document_preview--number">№{{ contract.id }}</div>
              <div class="document_preview--bank">АО «Kaspi Bank»</div>
              <div class="document_preview--data">19.08.2021</div>
            </div>
            <div class="document_preview--title" v-if="contract.type">
              {{ contract.type.full_name_ru }}
            </div>
            <div class="document_preview--stasusline">
              <div class="document_preview--stasustext">
                Статус: {{ contract.stage.title }}
              </div>
              <div v-if="contract.files && contract.files.length != 0">
                <div
                  class="document_preview--download"
                  v-for="file in contract.files"
                  :key="file.id"
                >
                  <a @click="downloadContractFile(file.id, file.file_path)">
                    Скачать: {{ file.file_path }}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="document_preview--right">
            <v-menu
              v-model="selectedStatus"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              content-class="statuses__toggle "
            >
              <template v-slot:activator="{ on, attrs }">
                <div class="col-lg-4 col__padding" v-bind="attrs" v-on="on">
                  <button type="submit" class="sign__button mr-2">
                    <i class="mdi mdi-share-variant-outline mr-2"></i>
                    Изменить статус
                  </button>
                </div>
              </template>

              <div class="column statuses__items">
                <div
                  class="statuses__item"
                  v-for="item in statuses"
                  :key="item.id"
                  @click="setStatus(item.id)"
                >
                  <p>
                    {{ item.title }}
                  </p>
                </div>
              </div>
            </v-menu>
          </div>
        </div>

        <div class="document_preview--body">
          <div class="row">
            <Comments
              v-if="contract"
              :commentTypeId="$parent.commentTypeId"
              :messageType="$parent.commentType"
              :contract="contract"
              :attachment="$parent.attachment"
              :type="'admin'"
            />
            <div class="col-lg-8">
              <div class="document_preview--fileright">
                <div class="document_preview--file">
                  <div id="pdf word__contract" ref="document" v-html="document"></div>
                </div>

                <div class="document_preview--btnline">
                  <div class="document_controll--item">
                    <div
                      class="document_controll--click document_controll--active"
                      @click="showDocument(contract.id)"
                    >
                      <div class="document_controll--icon">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M14.5 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V7.5L14.5 2Z"
                            stroke="black"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M14 2V8H20"
                            stroke="black"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      <div class="document_controll--text">Посмотреть договор</div>
                    </div>

                    <div v-if="contract.stage && contract.stage.name == 'signing_kisc'" class="mr-2">
                      <button class="sign__button pointer" @click="handleSend()">
                        <i class="mdi mdi-check-circle-outline mr-1"></i>
                        Подписать
                      </button>
                    </div>
                    <!-- <div class="document_controll--edit" @click="$router.push('/visa/update/'+contract.id)">
                                        Редактировать договор
                                    </div> -->
                  </div>

                  <div
                    class="document_controll--item"
                    v-for="(attach, a_id) in contract.attachments"
                    :key="attach.id"
                  >
                    <div
                      class="document_controll--click"
                      @click="showAttachment(attach, attach.id)"
                    >
                      <!--  document_controll--active -->
                      <div class="document_controll--icon">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M14.5 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V7.5L14.5 2Z"
                            stroke="black"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M14 2V8H20"
                            stroke="black"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>

                      <div class="document_controll--text">
                        Посмотреть приложение №{{ a_id + 1 }}
                      </div>
                    </div>
                    <p>{{ attach.stage.title }}</p>
                    <div
                      v-if="attach.stage && attach.stage.name == 'signing_kisc'"
                      class="mr-2"
                    >
                      <button
                        class="sign__button pointer"
                        @click="handleSend(a_id, 'attachment')"
                      >
                        <i class="mdi mdi-check-circle-outline mr-1"></i>
                        Подписать
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Comments from "../../../components/Comments";
export default {
  components: {
    Comments,
  },
  data() {
    return {
      statuses: [],
      selectedStatus: false,
      contract: [],
      document: "",
      ready: "",
      esp__array: [],
      message: "",
      messageColor: "",
      signAttachmentId: "",
      typeSign: "",
      currentAttachIndex: "",
    };
  },
  created() {
    this.connection = new WebSocket("wss://127.0.0.1:13579/");
    this.connection.onopen = () => {
      this.ready = true;
    };
    this.connection.onmessage = (e) => {
      const arr = JSON.parse(e.data);
      const parse__arr = arr;
      this.esp__array = parse__arr;

      if (typeof this.esp__array !== "undefined" && this.esp__array.code == 200) {
        this.signDocument(this.esp__array.responseObject, this.typeSign);
      }
    };
  },
  methods: {
    deleteFile(id) {
      this.$axios({
        method: "delete",
        url: this.$API_URL + this.$API_VERSION + "user/delete/file?id=" + id,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token_kcmr")}`,
        },
        // responseType: 'arraybuffer'
      })
        .then((response) => {
          this.$toast.open({
            message: response.data.message,
            type: "success",
            position: "bottom",
            duration: 4000,
            queue: true,
          });
          this.getDocument(this.contract.id);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    downloadContractFile(id, name) {
      this.$axios({
        method: "get",
        url: this.$API_URL + this.$API_VERSION + "document/file?id=" + id,
        responseType: "arraybuffer",
        data: this.sobs,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token_kcmr")}`,
        },
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", name); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateAttachment(contract_id, id) {
      this.$router.push("/create/mspd/attachment/" + contract_id + "/" + id);
    },
    convertDate(date) {
      if (date) {
        let d = date.split("T")[0];
        return d.split("-")[2] + "." + d.split("-")[1] + "." + d.split("-")[0];
      }
    },
    setStatus(id) {
      this.$axios({
        method: "post",
        url: this.$API_URL + this.$API_VERSION + "admin/set/contract/status",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token_kcmr")}`,
        },
        data: {
          id: this.contract.id,
          status: id,
        },
      })
        .then((response) => {
          this.$toast.open({
            message: response.data.message,
            type: "success",
            position: "bottom",
            duration: 4000,
            queue: true,
          });

          this.getDocument(this.contract.id);
          this.selectedStatus = false;
        })
        .catch((error) => {
          console.warn(error);
        });
    },
    setAttachmentStatus(id, attach_id) {
      this.$axios({
        method: "post",
        url: this.$API_URL + this.$API_VERSION + "admin/set/attachment/status",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token_kcmr")}`,
        },
        data: {
          id: attach_id,
          status: id,
        },
      })
        .then((response) => {
          this.$toast.open({
            message: response.data.message,
            type: "success",
            position: "bottom",
            duration: 4000,
            queue: true,
          });

          this.getDocument(this.contract.id);
          this.selectedStatus = false;
        })
        .catch((error) => {
          console.warn(error);
        });
    },

    documentStatuses() {
      this.$axios({
        method: "get",
        url: this.$API_URL + this.$API_VERSION + "documents/statuses",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token_kcmr")}`,
        },
      })
        .then((response) => {
          this.statuses = response.data;
        })
        .catch((error) => {
          console.warn(error);
        });
    },
    signDocument(cms, type) {
      let url =
        "admin/documents/" + this.contract.type.name + "/" + this.contract.id + "/sign";
      if (type == "attachment") {
        url =
          "admin/documents/" +
          this.contract.type.name +
          "/" +
          this.contract.id +
          "/" +
          this.currentAttachIndex +
          "/sign";
      }
      this.$axios({
        method: "patch",
        url: this.$API_URL + this.$API_VERSION + url,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token_kcmr")}`,
        },
        data: {
          document_cms: cms,
          document_id: this.contract.id,
          attachment_id: this.currentAttachIndex,
        },
      })
        .then((response) => {
          if (response.data) {
            this.$toast.open({
              message: "Документ успешно подписан",
              type: "success",
              position: "bottom",
              duration: 4000,
              queue: true,
            });
          }
          this.showDocument(this.contract.id);
          this.getDocument(this.contract.id);
        })
        .catch((error) => {
          console.warn(error);
          this.$toast.open({
            message: "Невалидный эцп",
            type: "error",
            position: "bottom",
            duration: 4000,
            queue: true,
          });
        });
    },

    handleSend(id = null, type = null) {
      let folder = this.contract.base_64;
      if (type == "attachment") {
        this.typeSign = "attachment";
        folder = this.contract.attachments[id].base_64;
        this.currentAttachIndex = this.contract.attachments[id].id;
      }
      if (this.ready == false) {
        this.showError = true;
      } else {
        this.showError = false;
        var createCAdESFromBase64Hash = {
          module: "kz.gov.pki.knca.commonUtils",
          method: "createCAdESFromBase64",
          args: ["PKCS12", "SIGNATURE", folder, true],
        };
        this.connection.send(JSON.stringify(createCAdESFromBase64Hash));
      }
    },

    getDocument(id) {
      this.$axios({
        method: "get",
        url: this.$API_URL + this.$API_VERSION + "admin/document?id=" + id,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token_kcmr")}`,
        },
      })
        .then((response) => {
          this.contract = response.data;
        })
        .catch((error) => {
          console.warn(error);
        });
    },
    showDocument(id) {
      this.$parent.commentType = "contract";
      this.$parent.commentTypeId = id;
      this.$axios({
        method: "get",
        url:
          this.$API_URL + this.$API_VERSION + "admin/documents/mastercard/show?id=" + id,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token_kcmr")}`,
        },
      })
        .then((response) => {
          this.document = response.data;
        })
        .catch((error) => {
          console.warn(error);
        });
    },
    showAttachment(attachment, id) {
      this.$parent.commentType = "attachment";
      this.$parent.commentTypeId = id;
      this.$parent.attachment = attachment;
      this.$axios({
        method: "get",
        url:
          this.$API_URL +
          this.$API_VERSION +
          "admin/documents/" +
          this.contract.type.name +
          "/attachment/show?id=" +
          id,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token_kcmr")}`,
        },
      })
        .then((response) => {
          this.document = response.data;
        })
        .catch((error) => {
          console.warn(error);
        });
    },
  },
  mounted() {
    this.documentStatuses();
    this.getDocument(this.$route.params.id);
    this.showDocument(this.$route.params.id);
    this.getComments(this.$route.params.id);
  },
  watch: {},
};
</script>

<style>
.word__contract {
  width: 873px;
  height: 900px;
  overflow-y: auto;
}
.statuses__toggle {
  min-width: 270px;
}
.statuses__items {
  border-radius: 9px;
  display: flex;
  flex-direction: column;
  padding: 15px;
  background: white;
}
.statuses__item {
  cursor: pointer;
}
.statuses__item:hover {
  color: #2a79f0;
}
</style>
